import * as React from "react";

// import ControlSection from "./Control";
import FeatureSection from "../FeatureSection";
import FeaturedPosts from "./featured-post";
import Posts from "./posts";
import RomanticBlogWrapper from "./style";

type RomanticBlogProps = {};

const RomanticBlog: React.FunctionComponent<RomanticBlogProps> = (props) => {
	return (
		<RomanticBlogWrapper {...props}>
			<FeaturedPosts />
			<FeatureSection />
			{/* <ControlSection /> */}
			<Posts />
		</RomanticBlogWrapper>
	);
};

export default RomanticBlog;
