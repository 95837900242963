import { graphql, useStaticQuery } from 'gatsby';

import Box from '../../home/components/Box';
import Cloud from '../../images/tools/cloud-computing.svg';
import Container from '../../home/components/UI/Container';
import Couchbase from '../../images/tools/couchbase.svg';
import FeatureBlock from '../../home/components/FeatureBlock';
import GraphQL from '../../images/tools/gql.svg';
import Heading from '../../home/components/Heading';
import Kubernetes from '../../images/tools/k8s.svg';
import ML from '../../images/tools/ml.svg';
import React from 'react';
import ReactJS from '../../images/tools/react.svg';
import SectionWrapper from './feature.style';
import Text from '../../home/components/Text';

const FEATURESSECTION = [
  {
    "icon": ReactJS,
    "title": "ReactJS",
    "description": "ReactJS's here, ReactJS's there, it's no surprise, ReactJS is at the top of Frontend game, the inspiration for all Frontend frameworks"
  },
  {
    "icon": GraphQL,
    "title": "GraphQL",
    "description": "We don't run oil cars, we run Tesla's, no gas, just nature's electricity, automatic IDE, docs, tools .e.t.c.."
  },
  {
    "icon": Couchbase,
    "title": "Couchbase",
    "description": "Imagine a database with both the power of PostgreSQL and Mongo?"
  },
  {
    "icon": Kubernetes,
    "title": "Kubernetes",
    "description": "Everything is a kubernetes cluster now, thanks to Google, even these serverless functions are just a K8S deployment in the background."
  },
  {
    "icon": Cloud,
    "title": "GCP/DO/AWS",
    "description": "A big shout out to Digital Ocean for the $10 Kubernetes clusters. 😘😉, GCP/AWS are catching up to this. lol"
  },
  {
    "icon": ML,
    "title": "Machine Learning",
    "description": "Bruh, AI is improving lives and providing more opportunities, people used to think it's the opposite, but maybe in 3022, but not in 2022."
  }
];

const FeatureSection = (props: any) => {

  const {
    secTitleWrapper = {
      mb: ['60px', '100px'],
    },
    secText = {
      as: 'span',
      display: 'block',
      textAlign: 'center',
      fontSize: '14px',
      letterSpacing: '0.15em',
      fontWeight: '700',
      color: '#ff4362',
      mb: '12px',
    },
    secHeading = {
      textAlign: 'center',
      fontSize: ['20px', '24px', '36px', '36px'],
      fontWeight: '700',
      color: '#0f2137',
      letterSpacing: '-0.025em',
      mb: '0',
      ml: 'auto',
      mr: 'auto',
      lineHeight: '1.12',
      width: '540px',
      maxWidth: '100%',
    },
    row = {
      flexBox: true,
      flexWrap: 'wrap',
      ml: ['-30px', '-30px', '-30px', '-25px', '-30px'],
      mr: ['-30px', '-30px', '-30px', '-25px', '-45px'],
    },
    col = {
      width: [1, 1 / 2, 1 / 2, 1 / 3],
      pl: ['30px', '30px', '30px', '25px', '45px'],
      pr: ['30px', '30px', '30px', '25px', '45px'],
      mb: ['50px', '70px'],
    },
    iconStyle = {
      width: ['72px', '72px', '72px', '72px', '82px'],
      height: ['72px', '72px', '72px', '72px', '82px'],
      mr: '20px',
      borderRadius: ['20px', '20px', '20px', '20px', '28px'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bg: '#ffecef',
      color: '#ff4361',
      fontSize: ['26px', '26px', '26px', '26px', '30px'],
    },
    featureTitle = {
      fontSize: ['17px', '19px'],
      fontWeight: '500',
      color: '#0f2137',
      letterSpacing: '-0.020em',
      lineHeight: '1.5',
      mb: ['10px', '13px'],
    },
    featureDescription = {
      fontSize: '15px',
      fontWeight: '400',
      color: '#5d646d',
      letterSpacing: '-0.025em',
      lineHeight: '1.88',
      mb: 0,
    }
  } = props;
  const Data = useStaticQuery(graphql`
    query {
      interiorJson {
        FEATURESSECTION {
          title
          description
          icon
        }
      }
    }
  `);

  return (
    <SectionWrapper id="feature_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="100% Open sourced" />
          <Heading
            {...secHeading}
            content="Our daily bread"
          />
        </Box>

        <Box {...row}>
          {FEATURESSECTION.map((item, index) => (
            <Box {...col} key={`feature-item-${index}`} className="feature_col">
              <FeatureBlock
                icon={<img src={item.icon} style={{ width: 50}} />}
                iconStyle={iconStyle}
                iconPosition="left"
                title={<Heading content={item.title} {...featureTitle} />}
                description={
                  <Text content={item.description} {...featureDescription} />
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// TODO Add this props, no time need to add more components
// FeatureSection.propTypes = {
//   sectionHeader: PropTypes.object,
//   sectionWrapper: PropTypes.object,
//   secTitleWrapper: PropTypes.object,
//   secText: PropTypes.object,
//   secHeading: PropTypes.object,
//   row: PropTypes.object,
//   col: PropTypes.object,
//   FeatureItemStyle: PropTypes.object,
//   iconStyle: PropTypes.object,
//   contentStyle: PropTypes.object,
//   featureTitle: PropTypes.object,
//   featureDescription: PropTypes.object,
// };

export default FeatureSection;
